import http from '@utils/http';
import notifyError from '@helpers';

const generateAnonymous = () => {
  if (!localStorage.getItem('uniqueId')) {
    localStorage.setItem('uniqueId', `anonymous${Math.floor(Math.random()
      * Math.floor(Math.random() * Date.now()) * Math.random())}`);
  }

  return localStorage.getItem('uniqueId');
};

export default {
  saveSiteEvent(eventName) {
    return new Promise((resolve, reject) => {
      http.post('user-behave-site', { uniqueId: generateAnonymous(), eventName }).then(
        ({ data }) => {
          resolve(data);
        },
      ).catch((error) => {
        notifyError(error);
        reject(error);
      });
    });
  },
};
