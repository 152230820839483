const getters = {
  textHeader: state => state.textHeader,
  showQuestions: state => state.showQuestions,
  showFreQuestions: state => state.showFreQuestions,
  showDetailHeader: state => state.showDetailHeader,
  showDetailHeader2: state => state.showDetailHeader2,
  hiddenHeader: state => state.hiddenHeader,
  showRegisterForm: state => state.showRegisterForm,
  showHear1: state => state.showHear1,
  showHear2: state => state.showHear2,
  showHear3: state => state.showHear3,
  showHear4: state => state.showHear4,
  showHear5: state => state.showHear5,
  changeColorHeader: state => state.changeColorHeader,
  showResult: state => state.showResult,
  closeToFriend: state => state.closeToFriend,
  logIn: state => state.logIn,
  linkCopy: state => state.linkCopy,
  expandAll: state => state.expandAll,
  showResYou: state => state.showResYou,
  showResCrowd: state => state.showResCrowd,
  showButonRegister: state => state.showButonRegister,
  backgroundColor: state => state.backgroundColor,
  startedPage: state => state.startedPage,
  step1: state => state.step1,
  step2: state => state.step2,
  step3: state => state.step3,
  step4: state => state.step4,
  step5: state => state.step5,
  step6: state => state.step6,
  step7: state => state.step7,
  step8: state => state.step8,
  step9: state => state.step9,
  step10: state => state.step10,
  step11: state => state.step11,
  step12: state => state.step12,
  crowdSurveyStep: state => state.crowdSurveyStep,
};
const actions = {
  gotToStepInitial({ commit }) {
    commit('resetState', false);
    commit('setStep1', true);
    commit('setShowHeader1', true);
  },
  // survey page
  gotToStep1({ commit }) {
    commit('resetState', false);
    commit('setStep2', true);
    commit('setShowHeader1', true);
    commit('setBackgroundColor', true);
  },
  showHeaderLogo({ commit }) {
    commit('setShowHeader2', true);
  },
  // registration page
  gotToStep2({ commit }) {
    commit('resetState', false);
    commit('setStep2', true);
    commit('setBackgroundColor', false);
    commit('setShowHeader1', true);
    commit('setShowButonRegister', true);
  },
  // login page
  gotToStep3({ commit }) {
    commit('resetState', false);
    commit('setStep3', true);
    commit('setLogin', true);
    commit('setShowHeader2', true);
  },
  // self result page
  gotToStep4({ commit }) {
    commit('resetState', false);
    commit('setStep4', true);
    commit('setLinkCopy', true);
    commit('setShowHeader1', true);
  },
  gotToStep5({ commit }) {
    commit('resetState', false);
    commit('setStep5', true);
    commit('setShowHeader4', true);
    commit('setBackgroundColor', true);
  },
  gotToStep7({ commit }) {
    commit('resetState', false);
    commit('setStep7', true);
    commit('setShowHeader2', true);
  },
  register({ commit }) {
    commit('resetState', false);
    commit('setShowHeader1', true);
    commit('setShowDetailHeader2', true);
  },
  startedPage({ commit }) {
    commit('resetState', false);
    commit('setShowHeader1', true);
    commit('setStartedPage', true);
  },
  startedCrowd({ commit }) {
    commit('setHiddenHeader', true);
    commit('setCrowdSurveyStep', true);
    commit('setStep1', false);
  },
  crowdSurveyPage({ commit }) {
    commit('resetState', false);
    commit('setShowHeader1', true);
    commit('setCrowdSurveyStep', true);
  },
  mainPage({ commit }) {
    commit('resetState', false);
    commit('setHiddenHeader', false);
  },
};
const mutations = {
  setTextHeader(state, text) {
    state.textheader = text;
  },
  setShowQuestions(state, status) {
    state.showQuestions = status;
  },
  setShowDetailHeader(state, status) {
    state.showDetailHeader = status;
  },
  setChangeColorHeader(state, status) {
    state.changeColorHeader = status;
  },
  setShowResult(state, status) {
    state.showResult = status;
  },
  setShowHeader1(state, status) {
    state.showHear1 = status;
  },
  setShowHeader2(state, status) {
    state.showHear2 = status;
  },
  setShowHeader3(state, status) {
    state.showHear3 = status;
  },
  setShowHeader4(state, status) {
    state.showHear4 = status;
  },
  setShowHeader5(state, status) {
    state.showHear5 = status;
  },
  setShowDetailHeader2(state, status) {
    state.showDetailHeader2 = status;
  },
  setCloseToFriend(state, status) {
    state.closeToFriend = status;
  },
  setShowRegisterForm(state, status) {
    state.showRegisterForm = status;
  },
  setLogin(state, status) {
    state.logIn = status;
  },
  setLinkCopy(state, status) {
    state.linkCopy = status;
  },
  setExpandAll(state, status) {
    state.expandAll = status;
  },
  setShowResYou(state, status) {
    state.showResYou = status;
  },
  setShowResCrowd(state, status) {
    state.showResCrowd = status;
  },
  setShowButonRegister(state, status) {
    state.showButonRegister = status;
  },
  setBackgroundColor(state, status) {
    state.backgroundColor = status;
  },
  setStartedPage(state, status) {
    state.startedPage = status;
  },
  setHiddenHeader(state, status) {
    state.hiddenHeader = status;
  },
  setStep1(state, status) {
    state.step1 = status;
  },
  setStep2(state, status) {
    state.step2 = status;
  },
  setStep3(state, status) {
    state.step3 = status;
  },
  setStep4(state, status) {
    state.step4 = status;
  },
  setStep5(state, status) {
    state.step5 = status;
  },
  setStep6(state, status) {
    state.step6 = status;
  },
  setStep7(state, status) {
    state.step7 = status;
  },
  setStep8(state, status) {
    state.step8 = status;
  },
  setStep9(state, status) {
    state.step9 = status;
  },
  setStep10(state, status) {
    state.step10 = status;
  },
  setStep11(state, status) {
    state.step11 = status;
  },
  setStep12(state, status) {
    state.step12 = status;
  },
  setCrowdSurveyStep(state, status) {
    state.crowdSurveyStep = status;
  },
  resetState(state, status) {
    state.showQuestions = status;
    state.showFreQuestions = status;
    state.showDetailHeader = status;
    state.showDetailHeader2 = status;
    state.showRegisterForm = status;
    state.changeColorHeader = status;
    state.showHear1 = status;
    state.showHear2 = status;
    state.showHear3 = status;
    state.showHear4 = status;
    state.showHear5 = status;
    state.showResult = status;
    state.closeToFriend = status;
    state.logIn = status;
    state.linkCopy = status;
    state.expandAll = status;
    state.showResYou = status;
    state.showResCrowd = status;
    state.showButonRegister = status;
    state.backgroundColor = status;
    state.step1 = status;
    state.step2 = status;
    state.step3 = status;
    state.step4 = status;
    state.step5 = status;
    state.step6 = status;
    state.step7 = status;
    state.step8 = status;
    state.step9 = status;
    state.step10 = status;
    state.step11 = status;
    state.step12 = status;
    state.crowdSurveyStep = status;
    state.startedPage = status;
    state.hiddenHeader = status;
  },
};
const state = {
  textHeader: 'next-generation Strengths assessment',
  showQuestions: true,
  showFreQuestions: true,
  showDetailHeader: true,
  showDetailHeader2: false,
  showRegisterForm: false,
  changeColorHeader: true,
  showHear1: true,
  showHear2: false,
  showHear3: false,
  showHear4: false,
  showHear5: false,
  showResult: false,
  closeToFriend: false,
  logIn: false,
  linkCopy: false,
  expandAll: false,
  showResYou: false,
  showResCrowd: false,
  showButonRegister: false,
  startedPage: false,
  step1: true,
  step2: false,
  step3: false,
  step4: false,
  step5: false,
  step6: false,
  step7: false,
  step8: false,
  step9: false,
  step10: false,
  step11: false,
  step12: false,
  crowdSurveyStep: false,
  backgroundColor: true,
  hiddenHeader: false,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
