<template>
  <v-app class="main"
  :class="[(changeColorHeader ? 'background_color_1' : 'background_color_2')]"
  >
    <!-- <Header v-if="!checkRoleAdmin"/> -->
    <HeaderAdmin v-if="checkRoleAdmin"/>
    <router-view class="main__content" />
    <Notification />
  </v-app>
</template>

<script>
// import Header from '@components/Header/Header.vue';
import HeaderAdmin from '@components/Header/HeaderAdmin.vue';
import Notification from '@components/Notification/Notification.vue';
import { mapGetters } from 'vuex';
import checkRole from '@helpers/adminFunction';

export default {
  name: 'App',
  components: {
    // Header,
    Notification,
    HeaderAdmin,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      changeColorHeader: 'showComponent/changeColorHeader',
      // showDetailHeader: 'showComponent/showDetailHeader',
    }),
    title() {
      if (process.env.VUE_APP_TITLE) {
        return process.env.VUE_APP_TITLE;
      }
      return 'InnerWorks';
    },
    showSecretDataModal() {
      return this.getProfile.userWithoutSecretAnswer === false;
    },
    checkRoleAdmin() {
      if (checkRole.isAdmin()) {
        return true;
      }
      return false;
    },
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
};
</script>

<style lang="scss">
  @import "./styles/style.scss";
  .container {
    padding: 0;
    background-color: #F3F4F6;
  }
</style>
