<template>
  <div>
    <div class="form__input_for_icon" :class="{ 'form-group-error': hasError }">
      <input
        :style="{'padding-right': isPassword ? '50px' : ''}"
        class="form__input"
        v-bind="$attrs"
        v-model="getValue"
        @focus="(e) => $emit('focus', e)"
        :type="typeInput"
      />
      <img v-if="isPassword && changeIcon"
           :src="eyeIcon" alt="eye-icon" class="eye-icon" @click="showPassword"/>
      <img v-else-if="isPassword && !changeIcon"
           :src="hideIcon" alt="eye-icon" class="eye-icon" @click="showPassword"/>
      <InformationForm
        v-if="!!icon"
        :icon="icon"
        size="1x"
        :tooltip="iconTooltip"
      />
    </div>
    <template v-if="hasError">
      <div class="form__input-error">
        <slot name="error-message"></slot>
        <!-- <span v-if="!$v.formData.password.required">Field is required</span>
        <span v-else-if="!$v.formData.password.minLength">
          Password must have at least
          {{ $v.formData.password.$params.minLength.min }} letters
        </span> -->
      </div>
    </template>
  </div>
</template>

<script>
import InformationForm from '@components/Onboarding/InformationForm.vue';
import eyeIcon from '@assets/eye-icon.svg';
import hideIcon from '@assets/hide-icon.svg';

export default {
  components: {
    InformationForm,
  },
  props: {
    value: [String, Number, Object],
    icon: String,
    iconTooltip: {
      type: String,
      default: '',
    },
    hasError: Boolean,
    isPassword: {
      type: Boolean,
      defaultValue: false,
    },
    cursiveText: {
      type: Boolean,
      default() { return true; },
    },
  },
  data: () => ({
    eyeIcon,
    hideIcon,
    typeInput: 'text',
    changeIcon: false,
  }),
  watch: {
    isPassword: {
      immediate: true,
      handler(val) {
        if (val) {
          this.typeInput = 'password';
        }
      },
    },
  },
  computed: {
    getValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    showPassword() {
      const { typeInput } = this;

      if (typeInput === 'password') {
        this.typeInput = 'text';
        this.changeIcon = true;
      } else {
        this.typeInput = 'password';
        this.changeIcon = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .form__input_for_icon {
    position: relative;
  }
  .eye-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
</style>
